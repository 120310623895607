@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wdth,wght@0,75..100,300..800;1,75..100,300..800&display=swap');

body{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

p{
    font-weight: 200;
    margin-top: 4%;
    font-size: 1.5em;
    font-style: normal;
}

Button{
    margin-top: 2%;
}

.heading_home{
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 4%;
}

.deleteButton{
    margin-left: auto;
    margin-right: 10px;
    margin-top: auto;
    font-size: 1.1em;
    color: red;
}

.deleteButton:hover{
    color: darkred;
    cursor: pointer;
}

.editButton{
    margin-left: 0;
    margin-right: 0;
    margin-top: auto;
    font-size: 1.1em;
    color: gray;
}

.editButton:hover{
    color: darkgray;
    cursor: pointer;
}

.error{
    color: red;
    font-size: 1.1em;
    margin-top: 2%;
}

.myReviewsWebsite{
    margin-left: auto;
    margin-right: 10px;
    margin-top: auto;
    font-size: 1.1em;
    color: gray;
}

.myReviewsWebsite:hover{
    color: darkgray;
    cursor: pointer;
}

.section-container {
    margin: 0 auto;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding: 20px;
    max-width: 600px;
}

.navbarText{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    margin-top: 25px;
    margin-left: 15px;
}

#navLinks{
font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

#navLinks a:first-child{
    color: white;
    background-color: #00AEEF;
}

#navLinks a:first-child:hover{
    color: white;
    background-color: #008FC5;
}

.homeTitle{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    color: #008FC5;
}

.homeDescription{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    color: #333333;
}

.urlInput {
    border: none;
    outline: none;
}

.urlInput:focus, input:focus {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.homeForm {
    display:flex;
    flex-direction:row;
    border: 2px solid #008FC5;
    border-radius: 0px;
    padding:1px;
  }

.homeForm input {
    flex-grow:2;
    border:none;
}
  
.homeForm:focus-within { 
    outline: 1px solid #00AEEF; 
  }
  
.homeButton {
    background:none;
    color: #00AEEF;
    border: none;
}

.homeButton:hover {
    background:none;
    color: #008FC5;
    border: none;
}

.homeButton {
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    font-size: 1.2em;
    margin-top: 0%;
    
}

#root{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.footer {
    width: 100%;
    height: 2.5rem;
    color: #333333;
    margin-top: auto;
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings: "wdth" 100;
  }


.footer-section-l {
    display: flex;
    justify-content: start;
    gap: 10px;
}

.footer-section-r {
    gap: 10px;
    display: flex;
    justify-content: end;
}

.footer a{
    text-decoration: none;
    color: #008FC5;
}

.registerForm{
    margin: 0 auto;
    border: #333333 1px solid;
    padding: 50px 0px 50px 0px;
    width: 50%;
}

@media (max-width: 768px) {
    .registerForm{
        width: 100%;
        margin-bottom: 200px;
    }
}

.registerForm label{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    justify-content: start;
}

.registerForm input{
    border: #333333 1px solid;
    border-radius: 0;
    width: 100%;
}

.registerForm div {
    margin: 10px;
    width: 350px;
}

.registerForm{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.registerForm button{
    color: white;
    background-color: #00AEEF;
    border: none;
    border-radius: 0;
    width: 350px;
}

.registerForm button:hover{
    color: white;
    background-color: #008FC5;
    border: none;
    border-radius: 0;
    width: 350px;
}

.registerForm a{
    text-decoration: none;
    color: #00AEEF;
}

.registerForm a:hover{
    text-decoration: none;
    color: #008FC5;
}

.privacyPolicy{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    color: #333333;
    margin-top: 2%;
    border: #333333 1px solid;
    margin: 2% 10% 0 10%;
    padding: 100px;
}

@media (max-width: 768px) {
    .privacyPolicy{
        margin: 2% 5% 0 5%;
        padding: 50px;
    }
}

.websiteCard{
    border: #333333 1px solid;
    border-radius: none;
}

.websiteInfo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.websiteInfo-left{
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.websiteInfoInside{
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-left: 15px;
    text-align: start;
}

.websiteInfoInside a{
    text-decoration: none;
    color: #00AEEF;
    margin-left: 1px !important;
}

.websiteInfoInside a:hover{
    text-decoration: none;
    color: #008FC5;
    margin-left: 1px !important;
}

.style-module_starRatingWrap__q-lJC{
    margin-left: -3px !important;
}

.websiteInfo-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.websiteInfo-right button{
    color: white;
    background-color: #00AEEF;
    border: none;
    border-radius: 0;
    width: 100%;
}

.websiteInfo-right button:disabled{
    color: white;
    background-color: #333333;
    border: none;
    border-radius: 0;
    width: 100%;
}

.reviewHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0px 10px 0px;
}

.reviewHeader-left{
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.reviewHeader-left h5{
    margin-left: 15px;
}

.reviewHeader-right{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reviewContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.reviewButtons{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.reviewContent p{
    font-family: "Open Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    margin: 0;
}

.my-3{
    border: #333333 1px solid;
    border-radius: 0;
}

.addReviewForm{
    border: #333333 1px solid;
    border-radius: 0;
    margin: 30px 0px 30px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    padding: 16px;
}

.addReviewButton {
    margin: 0;
    border: 0;
    background: none;
    color: #00AEEF;
}

.addReviewButton:hover {
    margin: 0;
    border: 0;
    background: none;
    color: #008FC5;
}

.button{
    color: white;
    background-color: #00AEEF;
    border: none;
    border-radius: 0;
    width: 100px;

}

.button:hover{
    color: white;
    background-color: #008FC5;
    border: none;
    border-radius: 0;
}

.buttonBad{
    color: white;
    background-color: #333;
    border: none;
    border-radius: 0;
    width: 100px;
}

.buttonBad:hover{
    color: white;
    background-color: #1D1E18;
    border: none;
    border-radius: 0;
}

.profile{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2%;
}

.profile :first-child{
    margin: 0 auto;
}